@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans Mono', monospace;
}

.json {
  overflow-y: hidden;
  &-loader {
    background-color: #1c1c1c;
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-wrapper {
    background-color: #1c1c1c;
    width: 100vw;
    height: 100vh;
    padding: 1rem;
  }

  &-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 769px) {
      flex-direction: column;
    }
  }
  &-textarea {
    padding: 1rem;
    width: 40vw;
    height: 90vh;
    border: 2px solid #06d6a0;
    border-radius: 0.5rem;
    background-color: #1c1c1c;
    color: white;
    resize: none;
    &:focus {
      outline: none;
    }
    @media only screen and (max-width: 769px) {
      width: 100%;
      height: 18rem;
    }
  }
  &-controls {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @media only screen and (max-width: 769px) {
      flex-direction: row;
    }
    &-button {
      width: 10rem;
      padding: 1rem;
      border-radius: 0.5rem;
      border: none;
      margin: 1rem;
      background-color: #06d6a0;
      color: white;
      font-weight: bolder;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      cursor: pointer;
      @media only screen and (max-width: 769px) {
        width: 8rem;
        padding: 0.8rem;
        margin: 0.5rem;
      }
    }
  }

  &-error {
    position: absolute;
    background-color: rgba(black, 0.8);
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    margin: 0;
    &-msg {
      background-color: #06d6a0;
      height: 10rem;
      border-radius: 0.5rem;
      padding: 1rem;
      width: 30rem;
      display: grid;
      place-items: center;
      align-items: center;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media only screen and (max-width: 769px) {
        width: 20rem;
      }
      &-text {
        color: white;
        margin-bottom: 0.5rem;
      }
      &-button {
        width: 10rem;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        font-weight: bolder;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        cursor: pointer;
      }
    }
  }
  &-header {
    margin-bottom: 1rem;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
  }
}
